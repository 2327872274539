//
// Components
//
if (!window.components) {
  window.components = {};
}

// alert
import("bootstrap/js/dist/alert.js");

// alert slide
import { AlertSlide } from "../javascripts/components/alertSlide.js";
const alertSlide = new AlertSlide();
alertSlide.addEvent();
window.components.alertSlide = alertSlide;

// btn
import { btn } from "../javascripts/components/btn";
window.components.btn = btn;

// confirmation
import { confirmation } from "../javascripts/components/confirmation.js";
confirmation.addEvent();

// checkbox
import { formsCheckbox } from "../javascripts/components/formsCheckbox.js";
formsCheckbox.addEvent();

// chip
import { FormsChip } from "../javascripts/components/formsChip.js";
const formsChip = new FormsChip();
formsChip.addEvent();

// dropdown
import("bootstrap/js/dist/dropdown.js");

// radio
import { formsRadio } from "../javascripts/components/formsRadio.js";
formsRadio.addEvent();

// textCount
import { formsTextCount } from "../javascripts/components/formsTextCount.js";
formsTextCount.addEvent();

// example
import { formsTextAreaExample } from "../javascripts/components/formsTextareaExample.js";
formsTextAreaExample.addEvent();

// toggleSwitch
import { toggleSwitch } from "../javascripts/components/formsSwitch.js";
toggleSwitch.addEvent();

// image uploader
import { imageUploader } from "../javascripts/components/imageUploader.js";
imageUploader.addEvent();

// menu sp sub
import { MenuSpSub } from "../javascripts/components/menuSpSub.js";
const menuSpSub = new MenuSpSub();
menuSpSub.addEvent();
window.components.menuSpSub = menuSpSub;

// menu sp
import { menuSp } from "../javascripts/components/menuSp.js";
menuSp.addEvent();

// tooltip
import Tooltip from "bootstrap/js/dist/tooltip.js";
window.components.tooltip = Tooltip;

// popover
import Popover from "bootstrap/js/dist/popover.js";
window.components.popover = Popover;

// if switch
import { switcherIfValue } from "../javascripts/components/switcherIfValue.js";
switcherIfValue.addEvent();

// checkbox as switcher
import { switcher } from "../javascripts/components/switcherByClick.js";
switcher.addEvent();

// validator
import { Validator } from "../javascripts/components/validator.js";
const validator = new Validator();
validator.addEvent();
window.components.validator = validator;

// menu pc dropmodal
import { menuPcDropmodal } from "../javascripts/components/menuPcDropmodal.js";
menuPcDropmodal.addEvent();
// sameHeight
import { sameHeight } from "../javascripts/components/sameHeight.js";
sameHeight.addEvent();
//
// Helpers
//
if (!window.helpers) {
  window.helpers = {};
}
import { getFormsIncludesFile } from "../javascripts/utils/getFormsIncludesFile.js";
window.helpers.getFormsIncludesFile = getFormsIncludesFile;

import { scrollTo } from "../javascripts/utils/scrollTo.js";
window.helpers.scrollTo = scrollTo;

//
// Initializers
//
import("../javascripts/config/initializers/bootstrap.js");
import("../javascripts/config/initializers/ujs.js");

//
// Features
//
// 以下のようにViewから読み込む
// - @javascript_features += ['kaminari/_paginator']

//
// Views
//
// 以下のようにViewから読み込む
// - @javascript_views += javascript_view_paths(__FILE__)
