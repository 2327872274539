export const formsRadio = {
  addEvent() {
    $("body").on("change", "input:radio", (e) => {
      const newStr = `input[name='${$(e.target).attr("name")}']`;
      $(newStr).each((index, input) => {
        $(input).parents("label").find(".form-radio-text").removeClass("form-radio-text-force");
      });
      $(e.target).parents("label").find(".form-radio-text").addClass("form-radio-text-force");
    });
    $(document).ready(() => {
      $(".form-radio-text")
        .parents("label")
        .find('input[type="radio"]:checked')
        .each((index, input) => {
          $(input).parents("label").find(".form-radio-text").addClass("form-radio-text-force");
        });
    });
  },
};
