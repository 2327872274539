let fixedHeight = null;

export const menuSp = {
  addEvent() {
    $("body").on("click", "[data-action='spMenuToggle']", () => {
      this.toggle();
    });
    $("body").on("click", "[data-action='spMenuOpen']", () => {
      this.open();
    });
    $("body").on("click", "[data-action='spMenuClose']", () => {
      this.close();
    });
    $("body").on("click", "[data-action='spMenuShowMenuBlog']", () => {
      this.showMenuBlog();
    });
    $("body").on("click", "[data-action='spMenuShowMenuMain']", () => {
      this.showMenuMain();
    });
  },
  getBackgroundStartY() {
    const currentPointY = $(window).scrollTop();
    let addY = 0;
    // 白背景の開始位置を加算する
    if (!this.isHeaderFixedHeight()) {
      if (currentPointY <= 16) {
        addY = 16 - currentPointY;
      }
    }
    return addY + 48;
  },
  rootTarget() {
    return $("[data-target='spMenuRoot']");
  },
  iconTarget() {
    return $("[data-action='spMenuToggle']");
  },
  mainMenuTarget() {
    const $rootTarget = this.rootTarget();
    return $rootTarget.find("[data-target='mainMenu']");
  },
  blogMenuTarget() {
    const $rootTarget = this.rootTarget();
    return $rootTarget.find("[data-target='blogMenu']");
  },
  isHeaderFixedHeight() {
    if (fixedHeight !== null) {
      return fixedHeight;
    }
    const $iconTarget = this.iconTarget();
    const dataFixedHeight = $iconTarget.data("fixed-height");
    if (dataFixedHeight) {
      fixedHeight = dataFixedHeight;
      return true;
    } else {
      fixedHeight = dataFixedHeight;
      return false;
    }
  },
  suspendScroll(e) {
    // alert("ok");
    e.preventDefault();
  },
  toggle() {
    const $rootTarget = this.rootTarget();
    if ($rootTarget.hasClass("active")) {
      this.close();
    } else {
      this.open();
    }
  },
  open() {
    // icon
    const $iconTarget = this.iconTarget();
    setTimeout(() => $iconTarget.addClass("style-bar"), 1);
    setTimeout(() => $iconTarget.addClass("style-close"), 200);

    //
    const $rootTarget = this.rootTarget();
    const backgroundStartY = this.getBackgroundStartY();
    $rootTarget.css("top", `${backgroundStartY}px`);

    $rootTarget.addClass("active");
    $rootTarget.addClass("style-transition");
    setTimeout(() => $rootTarget.addClass("transition-background"), 1);
    setTimeout(() => $rootTarget.addClass("transition-list"), 170);

    document.addEventListener("touchmove", this.suspendScroll, {
      passive: false,
    });
    document.addEventListener("mousewheel", this.suspendScroll, {
      passive: false,
    });
  },
  close() {
    // icon
    const $iconTarget = this.iconTarget();
    setTimeout(() => $iconTarget.removeClass("style-close"), 1);
    setTimeout(() => $iconTarget.removeClass("style-bar"), 200);

    //
    const $rootTarget = this.rootTarget();
    $rootTarget.removeClass("transition-background");
    $rootTarget.removeClass("transition-list");
    setTimeout(() => $rootTarget.removeClass("active"), 300);

    // メニューを初期化する
    setTimeout(() => {
      // 初期化
      const $mainMenuTarget = this.mainMenuTarget();
      const $blogMenuTarget = this.blogMenuTarget();
      $mainMenuTarget.removeClass("style-invisible");
      $mainMenuTarget.removeClass("style-transition-main");
      $blogMenuTarget.addClass("style-invisible");
      $blogMenuTarget.addClass("style-transition-sub");
    }, 310);

    document.removeEventListener("touchmove", this.suspendScroll, {
      passive: false,
    });
    document.removeEventListener("mousewheel", this.suspendScroll, {
      passive: false,
    });
  },
  showMenuBlog() {
    const $mainMenuTarget = this.mainMenuTarget();
    const $blogMenuTarget = this.blogMenuTarget();

    // 初期化
    $mainMenuTarget.removeClass("style-invisible");
    $mainMenuTarget.removeClass("style-transition-main");
    $blogMenuTarget.addClass("style-invisible");
    $blogMenuTarget.addClass("style-transition-sub");

    // mainを非表示にする
    setTimeout(() => {
      $mainMenuTarget.addClass("style-transition-main");
    }, 10);
    setTimeout(() => {
      $mainMenuTarget.addClass("style-invisible");
      $mainMenuTarget.removeClass("style-transition-main");
    }, 200);

    // blogを表示する
    setTimeout(() => {
      $blogMenuTarget.removeClass("style-invisible");
    }, 210);
    setTimeout(() => {
      $blogMenuTarget.removeClass("style-transition-sub");
    }, 220);
  },

  showMenuMain() {
    const $mainMenuTarget = this.mainMenuTarget();
    const $blogMenuTarget = this.blogMenuTarget();

    // 初期化
    $mainMenuTarget.addClass("style-invisible");
    $mainMenuTarget.addClass("style-transition-main");
    $blogMenuTarget.removeClass("style-invisible");
    $blogMenuTarget.removeClass("style-transition-sub");

    //blogをフェードアウトさせる
    setTimeout(() => {
      $blogMenuTarget.addClass("style-transition-sub");
    }, 10);
    setTimeout(() => {
      $blogMenuTarget.addClass("style-invisible");
      $blogMenuTarget.removeClass("style-transition-sub");
    }, 200);

    // mainを表示する
    setTimeout(() => {
      $mainMenuTarget.removeClass("style-invisible");
    }, 210);
    setTimeout(() => {
      $mainMenuTarget.removeClass("style-transition-main");
    }, 220);
  },
};
