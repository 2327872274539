//
// row > .col-x > [data-same-height]の構造で動作する。
// rowごとに高さを整える
//
export const sameHeight = {
  addEvent() {
    // sameheighがあるときだけ、登録する
    const heights = "[data-same-height]";
    if (heights.length > 0) {
      $(document).ready(() => {
        this.sameHeight();
      });
      $(window).resize(() => {
        this.sameHeight();
      });
    }
  },
  sameHeight() {
    if (this.isSp()) {
      $("[data-same-height]").each((_, list) => {
        $(list).height("");
      });
      return;
    }
    const listFirsts = $('.row > div[class^="col-"]:first-child [data-same-height]');
    listFirsts.each((_, listFirst) => {
      const $row = $(listFirst).closest(".row");
      const $lists = $row.find("[data-same-height]");
      let maxHeight = 0;
      // 一度消す
      $lists.each((_, list) => {
        $(list).height("");
      });

      $lists.each((_, list) => {
        const listHeight = $(list).height();
        if (maxHeight < listHeight) {
          maxHeight = listHeight;
        }
      });
      $lists.height(maxHeight);
    });
  },
  isSp() {
    if (window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  },
};
