export const toggleSwitch = {
  addEvent() {
    $(".form-switch").on("click", (e) => {
      this.click(e.target);
    });
    $(".form-switch").each((index, input) => {
      this.setup(input);
    });
  },
  click(el) {
    const $this = $(el);
    const $input = $this.find('input[type="checkbox"]');
    if ($input.prop("checked")) {
      $input.prop("checked", false).change();
    } else {
      $input.prop("checked", true).change();
    }
    this.reflect(el);
  },
  reflect(el) {
    const $this = $(el);
    const $input = $this.find('input[type="checkbox"]');
    const $target = $input.closest(".form-switch");
    if ($input.prop("checked")) {
      $target.addClass("style-active");
      $target.removeClass("style-inactive");
    } else {
      $target.removeClass("style-active");
      $target.addClass("style-inactive");
    }
  },
  setup(el) {
    const $this = $(el);
    const $input = $this.find('input[type="checkbox"]');
    if ($input.prop("checked")) {
      // 後からアニメーションクラスを付与してアニメーションをカットする
      $this.addClass("force-active");
      setTimeout(function () {
        $this.addClass("style-active");
        $this.removeClass("force-active");
      }, 1);
    } else {
      $this.addClass("style-inactive");
    }
  },
};
