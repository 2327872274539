import Modal from "bootstrap/js/dist/modal.js";

export const confirmation = {
  addEvent() {
    $(document).on("click.confirmation", '[data-toggle="confirmation"]', (e) => {
      this.showConfirmation(e.target);
    });

    $(document).on("click", '[data-modal-bypass-submit="true"]', (e) => {
      this.submitClick(e);
    });
  },
  showConfirmation(el) {
    this.setUpModal(el);
  },
  setUpModal(el) {
    let $btn = $(el);
    let $modal = $("#confirmationAlertModal");
    const $data = $(el);
    const title = $data.data("title");
    const body = $data.data("body");
    const label = $data.data("label");
    const href = $data.data("href");
    const method = $data.data("http-method");
    const action = $data.data("modal-action");
    const submit = $data.data("submit");

    // 存在していたら一回消す
    if ($modal[0]) {
      $modal.remove();
    }

    const modalHtml =
      '<div class="modal fade" id="confirmationAlertModal"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><div class="modal-title">Modal title</div><a class="close" data-bs-dismiss="modal"></a></div><div class="modal-body"><p>Modal body text goes here.</p></div><div class="modal-footer"><div class="modal-footer-main"><a class="btn btn-primary">Save changes</a></div><div class="modal-footer-sub"><a class="btn btn-text" data-bs-dismiss="modal" href="">キャンセル</a></div></div></div></div></div>';
    if (action || submit) {
      $btn.parent().append(modalHtml);
    } else {
      $("body").append(modalHtml);
    }
    $modal = $("#confirmationAlertModal");

    $modal.find(".modal-title").text(title);
    $modal.find("p").text(body);
    $btn = $modal.find(".modal-footer-main").children("a");
    $btn.text(label);

    if (href) {
      $btn.attr("href", href);
    }
    if (method) {
      $btn.attr("data-method", method);
    }
    if (action) {
      $btn.attr("data-action", action);
    }
    if (submit) {
      $btn.attr("data-modal-bypass-submit", true);
    }
    const modalInstance = new Modal($modal[0]);
    modalInstance.show();
  },
  success(message) {
    const $modal = $("#confirmationAlertModal");
    $("#confirmationAlertModal").modalInfoClose();
    const $sub = $modal.find(".modal-footer-sub");
    $modal.find("p").text(message);
    $sub.addClass("hidden");
    const $btn = $modal.find(".modal-footer-main").children("a");
    $btn.text("閉じる");
    $btn.removeAttr("href");
    $btn.removeAttr("data-action");
    $btn.removeAttr("data-method");
    $btn.removeAttr("data-modal-bypass-submit");
    $btn.attr("data-bs-dismiss", "modal");
  },
  error(message) {
    $("#confirmationAlertModal").modalAlert(message);
  },
  submitClick(e) {
    const $clickTarget = $(e.target);
    const $form = $clickTarget.closest("form");
    const $submit = $form.find('input[type="submit"]');
    $submit.click();
  },
  moveBodyEnd: function () {
    const $modal = $("#confirmationAlertModal");
    $modal.appendTo("body");
    // showをすることで、eventがアタッチされる
    const modalInstance = new Modal($modal[0]);
    modalInstance.show();
  },
};
