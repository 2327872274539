// url-upload, url-deleteをdata attrに入れると、ajax版として動作する
export const imageUploader = {
  addEvent() {
    // ドラッグ＆ドロップ
    $(document).on("dragover", '[data-action="drop->imageuploader#drop"]', (e) => {
      this.dragover(e);
    });
    $(document).on("dragleave", '[data-action="drop->imageuploader#drop"]', (e) => {
      this.dragleave(e);
    });
    $(document).on("drop", '[data-action="drop->imageuploader#drop"]', (e) => {
      this.drop(e);
    });
    // クリックアップロード
    $(document).on("click", '[data-action="click->imageuploader#click"]', (e) => {
      this.click(e);
    });
    // 削除
    $(document).on("click", '[data-action="click->imageuploader#delete"]', (e) => {
      this.delete(e);
    });
    // setした後のコールバック
    $(document).on("change", 'input[data-target="imageuploader.input"]', (e) => {
      this.selected(e);
    });
  },
  //
  // Target
  //
  rootTarget(el) {
    const $this = $(el);
    const rootTarget = $this.closest('[data-target="imageuploader.parent"]');
    return rootTarget;
  },
  dropTarget(el) {
    const dropTarget = this.rootTarget(el).find('[data-action="drop->imageuploader#drop"]');
    return dropTarget;
  },
  inputTarget(el) {
    const inputTarget = this.rootTarget(el).find('input[data-target="imageuploader.input"]');
    return inputTarget;
  },
  normalIconTarget(el) {
    return this.rootTarget(el).find('[data-target="icon-normal"]');
  },
  uploadingIconTarget(el) {
    return this.rootTarget(el).find('[data-target="icon-uploading"]');
  },
  holderBlankTarget(el) {
    return this.rootTarget(el).find('[data-taget="imageuploader.holderBlank"]');
  },
  holderImageTarget(el) {
    return this.rootTarget(el).find('[data-taget="imageuploader.holderImage"]');
  },
  //
  // data
  //
  data(e) {
    var self = this;
    function isAjax(e) {
      return urlUpload(e) != "";
    }
    function urlUpload(e) {
      var $root = self.rootTarget(e.target);
      return $root.data("url-upload");
    }
    function urlDelete(e) {
      var $root = self.rootTarget(e.target);
      return $root.data("url-delete");
    }
    return {
      isAjax: isAjax(e),
      urlUpload: urlUpload(e),
      urlDelete: urlDelete(e),
      classDragging: "is-drogging",
    };
  },
  //
  // Public
  //
  dragover(e) {
    // preventしないと、dropが取れない
    e.preventDefault();
    this.dropTarget(e.target).addClass(this.data(e).classDragging);
  },
  dragleave(e) {
    // preventしないと、dropが取れない
    e.preventDefault();
    this.dropTarget(e.target).removeClass(this.data(e).classDragging);
  },
  drop(e) {
    e.preventDefault();
    const files = e.originalEvent.dataTransfer.files;
    const input = this.inputTarget(e.target)[0];
    input.files = files;
    $(input).change(); // 発火
  },
  click(e) {
    const $input = this.inputTarget(e.target);
    $input.click();
  },
  delete(e) {
    if (this.data(e).isAjax) {
      this.ajaxDelete(e);
    } else {
      this.setImageToNull(e);
    }
  },
  selected(e) {
    if (this.data(e).isAjax) {
      this.ajaxUpload(e);
    } else {
      this.setImageToFileField(e);
    }
  },
  //
  // Private
  //

  // inputへの操作
  setImageToFileField(e) {
    // inputがthisに格納されている
    let $this = $(e.target);
    let $root = $this.closest('[data-target="imageuploader.parent"]');
    const $holderBlank = this.holderBlankTarget(e.target);
    const $holderImage = this.holderImageTarget(e.target);
    $holderBlank.addClass("hidden");
    $holderImage.removeClass("hidden");

    const $image = $root.find("img");
    const file = e.target.files[0];
    if (!file) {
      return false;
    }
    const reader = new FileReader();
    if (file.type.indexOf("image") < 0) {
      return false;
    }
    reader.onload = (function (file) {
      return function (event) {
        $image.attr("src", event.target.result);
      };
    })(file);
    reader.readAsDataURL(file);

    // ajaxでupload済みなので、fileFieldからは消す
    if (this.data(e).isAjax) {
      $this = $(this);
      $root = this.rootTarget(e.target);
      const inputID = "#" + $(this.inputTarget($this)[0]).attr("id");
      // form reset
      $(inputID).replaceWith($(inputID).clone());
      $(inputID).val("");
    }
  },
  setImageToNull(e) {
    var $this = $(this);
    var $root = this.rootTarget(e.target);
    var inputID = "#" + $(this.inputTarget($this)[0]).attr("id");
    // form reset
    // this.formImageBackgroundTarget.value = '' // と直接リセットのほうがうまくいくかも
    $(inputID).replaceWith($(inputID).clone());
    $(inputID).val("");
    // img reset
    var $holderBlank = this.holderBlankTarget(e.target);
    var $holderImage = this.holderImageTarget(e.target);
    var img = $holderImage.find("img");
    img.attr("src", "");
    $holderBlank.removeClass("hidden");
    $holderImage.addClass("hidden");
  },

  // for ajax
  ajaxUpload(e) {
    this.enableUploading(e);
    const self = this;
    const tempForm = $(e.target).parents("form:first");
    let form;
    if (window.helpers.getFormsIncludesFile) {
      form = window.helpers.getFormsIncludesFile.getForm(tempForm);
    } else {
      form = tempForm;
    }
    const formData = new FormData(form[0]);
    $.ajax({
      type: "POST",
      url: this.data(e).urlUpload,
      data: formData,
      processData: false,
      contentType: false,
    })
      .done(function (response, textStatus, jqXHR) {
        if (response.result) {
          self.disableUploading(e);
          self.setImageToFileField(e);
        } else {
          self.alert(response.message);
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        self.disableUploading(e);
        self.alert("システムエラーが発生しました");
      });
  },
  ajaxDelete(e) {
    var self = this;
    $.ajax({
      type: "DELETE",
      url: this.data(e).urlDelete,
    })
      .done(function (response, textStatus, jqXHR) {
        if (response.result) {
          self.setImageToNull(e);
        } else {
          self.alert(response.message);
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        self.alert("システムエラーが発生しました");
      });
  },
  // アップロード中の画面表示
  enableUploading(e) {
    this.normalIconTarget(e.target).addClass("hidden");
    this.uploadingIconTarget(e.target).removeClass("hidden");
  },
  disableUploading(e) {
    this.normalIconTarget(e.target).removeClass("hidden");
    this.uploadingIconTarget(e.target).addClass("hidden");
  },

  // アラート
  alert: function (message) {
    if (window.components.alertSlide) {
      window.components.alertSlide.callByFunction(message);
    } else {
      alert(message);
    }
  },
};
