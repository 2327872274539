// タイトルをSPAで更新する場合は、以下をコール
// window.components.menuSpSub.title("xxxx")
export class MenuSpSub {
  constructor() {
    this.timeoutIDs = [];
  }
  // addEvent
  addEvent() {
    // submenuがあるときだけ監視する
    $(".menu-sp-sub").each(() => {
      $("body").on("click", "[data-action='spSubMenuOpen']", () => {
        this.open();
      });
      $("body").on("click", "[data-action='spSubMenuClose']", () => {
        this.close();
      });
      $(window).scroll(() => {
        this.position();
      });
      $("body").on("click", "[data-action='spSubMenuToggle']", () => {
        this.toggle();
      });
    });
  }

  //
  // target
  //
  rootTarget() {
    return $("[data-target='spSubMenuRoot']");
  }
  backgroundTarget($rootTarget) {
    return $rootTarget.parent().find(".menu-sp-sub-background");
  }
  titleTarget() {
    return $("[data-target='spSubMenuTitle']");
  }
  getHeaderSize($rootTarget) {
    let headerSize = $rootTarget.data("header-height");
    if (!headerSize) {
      headerSize = 48;
    }
    return headerSize;
  }
  //
  // public
  //
  toggle() {
    const $rootTarget = this.rootTarget();
    if ($rootTarget.hasClass("active")) {
      this.close();
    } else {
      this.open();
    }
  }
  open() {
    const $rootTarget = this.rootTarget();
    this.clearAnimation();

    // close stateになっていることを保証する
    $rootTarget.removeClass("active");
    $rootTarget.removeClass("style-closing");
    $rootTarget.removeClass("transition-height");
    $rootTarget.removeClass("transition-list");

    // 開始
    $rootTarget.addClass("active");
    $rootTarget.addClass("style-transition");

    this.timeoutIDs.push(setTimeout(() => $rootTarget.addClass("style-opening"), 10));
    this.timeoutIDs.push(setTimeout(() => $rootTarget.removeClass("style-opening"), 100));

    // 高さ調整
    this.timeoutIDs.push(setTimeout(() => $rootTarget.addClass("transition-height"), 1));

    // リストスライド
    this.timeoutIDs.push(setTimeout(() => $rootTarget.addClass("transition-list"), 50));

    // backdrop
    this.addBackground($rootTarget);
    const $backgroundTarget = this.backgroundTarget($rootTarget);
    this.timeoutIDs.push(setTimeout(() => $backgroundTarget.addClass("transition-background"), 1));

    // 背面スクロール固定の場合
    if (this.isOverflowScoll()) {
      $("body").addClass("modal-open");
    }
  }
  close() {
    const $rootTarget = this.rootTarget();
    // 連続closeをブロックする
    if ($rootTarget.hasClass("style-closing")) {
      return;
    }
    this.clearAnimation();

    // close stateになっていることを保証する
    $rootTarget.addClass("active");
    $rootTarget.removeClass("style-closing");
    $rootTarget.addClass("transition-height");
    $rootTarget.addClass("transition-list");

    $rootTarget.addClass("style-closing");
    // リストスライド
    this.timeoutIDs.push(setTimeout(() => $rootTarget.removeClass("transition-list"), 1));

    // 高さ調整
    this.timeoutIDs.push(setTimeout(() => $rootTarget.removeClass("transition-height"), 100));

    // background
    const $backgroundTarget = this.backgroundTarget($rootTarget);
    this.timeoutIDs.push(
      setTimeout(() => $backgroundTarget.removeClass("transition-background"), 80),
    );
    this.timeoutIDs.push(setTimeout(() => $backgroundTarget.remove(), 580));

    // 仕上げ
    this.timeoutIDs.push(setTimeout(() => $rootTarget.removeClass("active"), 580));
    this.timeoutIDs.push(setTimeout(() => $rootTarget.removeClass("style-closing"), 580));

    // 背面スクロール固定の場合
    if (this.isOverflowScoll()) {
      $("body").removeClass("modal-open");
    }
  }
  position() {
    const currentPointY = $(window).scrollTop();
    const $rootTarget = this.rootTarget();
    const headerSize = this.getHeaderSize($rootTarget);

    let position = headerSize - currentPointY;
    if (position < 0) {
      $rootTarget.addClass("style-fixed");
    } else {
      $rootTarget.removeClass("style-fixed");
    }
    if (!this.isOverflowScoll()) {
      // 移動したとき、openであればcloseする
      if ($rootTarget.hasClass("active")) {
        this.close();
      }
    }
  }
  title(text) {
    const $titleTarget = this.titleTarget();
    $titleTarget.html(text);
  }

  //
  // private
  //

  clearAnimation() {
    this.timeoutIDs.forEach((timeoutID) => {
      clearTimeout(timeoutID);
    });
    this.timeoutIDs = [];
  }

  addBackground($rootTarget) {
    let $backgroundTarget = this.backgroundTarget($rootTarget);
    if (!$backgroundTarget[0]) {
      $rootTarget.after("<div class='menu-sp-sub-background' data-action='spSubMenuClose'></div>");
    }
    $backgroundTarget = this.backgroundTarget($rootTarget);
    const headerSize = this.getHeaderSize($rootTarget);
    const currentPointY = $(window).scrollTop();
    let cssTop = 48;
    if (currentPointY <= headerSize) {
      cssTop = headerSize - currentPointY;
    }
    $backgroundTarget.css("top", `${cssTop}px`);
  }

  isOverflowScoll() {
    const $rootTarget = this.rootTarget();
    if ($rootTarget.data("type") == "spSubMenuRoot.overflowScroll") {
      return true;
    } else {
      return false;
    }
  }
}
