// チェックボックス
export const formsCheckbox = {
  addEvent() {
    $("body").on("change", "input:checkbox", (e) => {
      const obj = $(e.target).parents("label").find(".form-checkbox-text");
      if ($(e.target).prop("checked")) {
        obj.addClass("form-checkbox-text-force");
      } else {
        obj.removeClass("form-checkbox-text-force");
      }
    });
    $(document).ready(() => {
      const checks = $(".form-checkbox-text")
        .parents("label")
        .find('input[type="checkbox"]:checked');
      checks.each((i, check) => {
        $(check).parents("label").find(".form-checkbox-text").addClass("form-checkbox-text-force");
      });
    });
  },
};
