//
// PC版メニューのドロップダウン＋他全体が暗くなるモーダル風味。
//

// 以下の構造をとる
// div.menu-pc-dropmodal
//   *[ここは何層あってもよい]
//     a[data-action="pcModalDropdown"]
//       | sample
//     .menu-pc-dropmodal__dropdown
//       ul
//         li
//           | sample

// 定数
const listHeight = 40; // aのheight (paddingを入れた結果)
const listWrapperPadding = 8; // ulの上下のpadding
const paddingBase = 10; // aのpaddingTopとpaddingBottom
const paddingBaseSpanTop = 16; // spanのpaddingTop
const paddingBaseSpanBottom = 8; // spanのpaddingBottom
const coefficientPx = 2; // アニメーションでスライドインさせるaの幅
const slideInDeley = 100; // リストの表示を遅らせる時間
const slideOutDeley = 200; // リストの非表示を遅らせる時間

export const menuPcDropmodal = {
  addEvent() {
    $("body").on("click", "[data-action='pcModalDropdown']", (e) => {
      this.toggle(e);
    });
    $("body").on("click", ".menu-pc-dropmodal__backdrop", (e) => {
      this.toggle(e);
    });
  },
  getRoot() {
    return $(".menu-pc-dropmodal");
  },
  getList(e, $root) {
    let $list = $root.find("li.menu-pc-dropmodal__list.is-active");
    if (!$list[0]) {
      $list = $(e.target).closest(".menu-pc-dropmodal__list");
    }
    return $list;
  },
  getBackdrop($root) {
    let $backdrop = $root.children(".menu-pc-dropmodal__backdrop");
    if (!$backdrop[0]) {
      $root.append("<div class='menu-pc-dropmodal__backdrop'></div>");
      $backdrop = $root.children(".menu-pc-dropmodal__backdrop");
    }
    return $backdrop;
  },
  getDropdown($list) {
    return $list.find(".menu-pc-dropmodal__dropdown");
  },
  toggle(e) {
    const $root = this.getRoot(e);
    const $list = this.getList(e, $root);
    if ($root.hasClass("is-active")) {
      this.close($root, $list);
    } else {
      this.open($root, $list, e);
    }
  },

  // 開く
  open($root, $list) {
    $root.addClass("is-active");

    //
    // arrow (下矢印)
    //
    $list.addClass("is-active");
    setTimeout(() => {
      $list.addClass("is-end");
    }, 10);

    //
    // backdrop (黒背景)
    //
    const $backdrop = this.getBackdrop($root);
    setTimeout(() => {
      $backdrop.addClass("is-active");
    }, 10);

    //
    // list
    //
    const $dropdown = this.getDropdown($list);
    const $ul = $dropdown.children("ul");
    const $lists = $ul.children("li");
    const listCount = $lists.length;
    const height = listCount * listHeight + listWrapperPadding * 2;

    //
    // 事前準備
    //
    // dropdownの高さを10分の1にして、小さい状態から元の状態にして、下へスライドさせている雰囲気を出す。
    $dropdown.height(height / 10);
    // リストを消す。初期の高さ
    const initialPadding = paddingBase - coefficientPx;
    const initialPaddingSpanTop = paddingBaseSpanTop - coefficientPx;
    const initialPaddingSpanBottom = paddingBaseSpanBottom - coefficientPx;
    $lists.each((index, list) => {
      $(list).children("span").css("padding-top", `${initialPaddingSpanTop}px`);
      $(list).children("span").css("padding-bottom", `${initialPaddingSpanBottom}px`);
      $(list).children("span").css("opacity", "0");

      $(list).children("a").css("padding-top", `${initialPadding}px`);
      $(list).children("a").css("padding-bottom", `${initialPadding}px`);
      $(list).children("a").css("opacity", "0");
    });

    //
    // dropdown (白背景)
    //
    // アニメーションを付与する
    setTimeout(() => {
      $dropdown.addClass("is-transition");
    }, 10);
    // 高さを元に戻す。アニメーション時間はCSSでコントロール。100msecを指定している。
    setTimeout(() => {
      $dropdown.height(height);
    }, 20);

    //
    // リストのスライドイン
    //

    // ulを表示に切り替える
    setTimeout(() => {
      $ul.css("opacity", "1");
    }, slideInDeley);

    $lists.each((index, list) => {
      setTimeout(
        () => {
          $(list).children("span").css("padding-top", `${paddingBaseSpanTop}px`);
          $(list).children("span").css("padding-bottom", `${paddingBaseSpanBottom}px`);
          $(list).children("span").css("opacity", "1");

          $(list).children("a").css("padding-top", `${paddingBase}px`);
          $(list).children("a").css("padding-bottom", `${paddingBase}px`);
          $(list).children("a").css("opacity", "1");
        },
        slideInDeley + index * 25,
      );
    });
  },

  // 閉じる
  close($root, $list) {
    $list.removeClass("is-end");

    const $dropdown = this.getDropdown($list);
    const $backdrop = this.getBackdrop($root);

    const $ul = $dropdown.children("ul");
    const $lists = $ul.children("li");
    const listCount = $lists.length;
    const height = listCount * listHeight + listWrapperPadding * 2;

    setTimeout(() => {
      $backdrop.removeClass("is-active");
    }, 10);

    // 消すときは一斉に。
    const initialPadding = paddingBase - coefficientPx;
    const initialPaddingSpanTop = paddingBaseSpanTop - coefficientPx;
    const initialPaddingSpanBottom = paddingBaseSpanBottom - coefficientPx;
    $lists.each((index, list) => {
      setTimeout(() => {
        $(list).children("span").css("padding-top", `${initialPaddingSpanTop}px`);
        $(list).children("span").css("padding-bottom", `${initialPaddingSpanBottom}px`);
        $(list).children("span").css("opacity", "0");

        $(list).children("a").css("padding-top", `${initialPadding}px`);
        $(list).children("a").css("padding-bottom", `${initialPadding}px`);
        $(list).children("a").css("opacity", "0");
      }, 10);
    });

    // 全部が終わったら高さを消す
    setTimeout(() => {
      $dropdown.height(height / 10);
    }, slideOutDeley);

    // 全ての状態の削除
    setTimeout(() => {
      $dropdown.removeClass("is-active");
      $root.removeClass("is-active");
      $list.removeClass("is-active");
    }, slideOutDeley + 150); // heightのtransitionを0.2秒にしているので。
  },
};
